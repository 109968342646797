.titlecomponent {
    .container {
        position: relative;
        background-size: cover;
        background-position: center;
        padding: 250px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            h2 {
                margin: 0;
                font-family: Montserrat, Cantarell;
                font-size: 100px;
            }
        }
    }
}
