@media all and (min-width: 600px) and (max-width: 1080px) {
    .titlescreen {
        padding: unset;
        height: 100vh;

        .container {
            .anim-text {
                .drawn-text {
                    width: unset;
                    .text {
                        font-family: ruthligos;
                        font-size: 350px;
                        fill: none;
                        stroke: #333;
                        stroke-width: 2;
                        stroke-dasharray: 1000;
                        stroke-dashoffset: 1000;
                        animation:
                            draw 3s forwards,
                            fillText 5s forwards;
                    }
                }
            }
            .title-sub {
                font-size: 25px;
                animation: fade 4s forwards;
            }
        }
    }
}
