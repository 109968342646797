.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .desc {
        margin: 50px;
        font-size: 22px;
    }
}
