.infocomponent {
    .container {
        position: relative;
        background-size: cover;
        background-position: center;
        color: white;
        display: flex;
        align-items: center;

        .title {
            h2 {
                margin-bottom: 20px;
                font-family: Montserrat, Cantarell;
                font-size: 75px;
            }
        }

        .desc {
            text-align: center;
            p {
                margin: 0 70px 10px 70px;
                font-size: 30px;
            }

            a {
                transition: 0.2s ease-in-out;
                display: inline-block;
                margin-top: 25px;
                color: whitesmoke;
                font-weight: bolder;
                font-size: 27px;
                background-color: darkslateblue;
                padding: 15px 70px;
                border-radius: 15px;
            }

            a:hover {
                transition: 0.2s ease-in-out;
                padding: 15px 90px;
            }
        }
    }
}
