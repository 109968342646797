header {
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    position: relative;
    z-index: 1000;

    .container {
        display: flex;
        height: inherit;
        margin: 0 50px;
        width: 100%;

        .logo {
            display: block;
            text-align: center;

            .drawn-text {
                .text {
                    font-family: ruthligos;
                    font-size: 50px;
                    fill: rgba(51, 51, 51, 1);
                    stroke: #333;
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                }

                .text:hover {
                    animation: draw 2s forwards;
                }
            }
        }

        .links {
            display: flex;
            margin: auto;
            margin-right: 0;

            li {
                display: flex;
                margin: 0 40px;
                list-style: none;
                width: 150px;
                justify-content: center;

                a {
                    font-family: Montserrat, Cantarell;
                    font-size: 27px;
                    text-decoration: none;
                    transition: all 0.3s ease;
                    color: black;
                    font-weight: normal;
                    position: relative;
                }

                a:visited {
                    color: black;
                }

                a:hover {
                    font-weight: bold;
                }

                a:hover::after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }

                a::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: black;
                    bottom: -5px;
                    left: 0;
                    transform: scaleX(0);
                    transform-origin: bottom right;
                    transition: transform 0.3s ease;
                }
            }
        }

        .hamburger {
            display: none;
            flex-direction: column;
            cursor: pointer;

            .bar {
                width: 25px;
                height: 3px;
                background-color: black;
                margin: 4px 0;
                transition: 0.4s;
            }
        }
    }
}
