footer {
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;

    .container {
        display: flex;
        flex-direction: column;
        height: inherit;
        margin: 0 50px;
        width: 100%;

        .logo {
            display: block;
            text-align: center;

            .drawn-text {
                .text {
                    font-family: ruthligos;
                    font-size: 50px;
                    fill: rgba(51, 51, 51, 1);
                    stroke: #333;
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                }

                .text:hover {
                    animation: draw 2s forwards;
                }
            }
        }

        .footnote {
            margin-bottom: 40px;
        }
    }
}
