@media all and (max-width: 599px) {
    .titlescreen {
        padding: unset;
        height: 20vh;
        min-height: 90vh;

        .container {
            .anim-text {
                height: unset;
                .drawn-text {
                    width: unset;
                    .text {
                        font-family: ruthligos;
                        font-size: 320px;
                        fill: none;
                        stroke: #333;
                        stroke-width: 2;
                        stroke-dasharray: 1000;
                        stroke-dashoffset: 1000;
                        animation:
                            draw 3s forwards,
                            fillText 5s forwards;
                    }
                }
            }
            .title-sub {
                font-size: 20px;
                animation: fade 4s forwards;
            }
        }
    }
}
