@media all and (max-width: 599px) {
    .titlecomponent {
        .container {
            padding: 100px;

            .title {
                h2 {
                    font-size: 63px;
                }
            }
        }
    }
}
