@media all and (max-width: 599px) {
    header {
        height: 130px;

        .container {
            .logo {
                .drawn-text {
                    .text {
                        font-size: 50px;
                    }
                }
            }

            .links {
                position: absolute;
                top: 0;
                flex-direction: column;
                background-color: white;
                width: unset;
                text-align: center;
                display: none;

                li {
                    margin: 8px 0;
                    width: 290px;

                    a {
                        font-size: 22px;
                    }
                }

                &.open {
                    display: flex;
                    justify-content: center;
                }
            }

            .hamburger {
                display: flex;
                justify-content: center;
                position: absolute;
                right: 30px;
                top: 35%;
            }
        }
    }
}
