@media all and (max-width: 599px) {
    form {
        width: 100%;
        padding: 0 20px;

        .form-group {
            label,
            input,
            textarea {
                font-size: 20px;
            }

            textarea {
                height: 100px;
            }
        }

        button {
            padding: 10px;
            font-size: 16px;
        }
    }
}
