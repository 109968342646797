@media all and (min-width: 600px) and (max-width: 1080px) {
    .infocomponent {
        .container {
            .title {
                h2 {
                    font-size: 55px;
                    text-align: center;
                }
            }

            .desc {
                p {
                    margin: 0 20px 10px 20px;
                    font-size: 25px;
                }

                a {
                    font-size: 25px;
                    padding: 10px 30px;
                }

                a:hover {
                    padding: 10px 50px;
                }
            }
        }
    }
}
