.contactcomponent {
    display: flex;
    justify-content: center;
    align-items: center;
    form {
        width: 350px;
        max-width: 450px;

        .form-group {
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;

            label {
                margin-bottom: 5px;
            }

            input,
            textarea {
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;
            }

            textarea {
                resize: none;
                height: 120px;
            }
        }

        button {
            transition: 0.2s ease-in-out;
            display: inline-block;
            margin-top: 25px;
            color: whitesmoke;
            font-weight: bolder;
            font-size: 20px;
            background-color: darkslateblue;
            padding: 15px 50px;
            border-radius: 15px;
            border: none;

            &:hover {
                transition: 0.2s ease-in-out;
                padding: 15px 70px;
                cursor: pointer;
            }
        }
    }
}
