@font-face {
    font-family: goodtiming;
    src: url('../font/goodtiming.otf');
}

@font-face {
    font-family: ruthligos;
    src: url('../font/Ruthligos.ttf');
}

@font-face {
    font-family: Montserrat;
    src: url('../font/Montserrat.ttf');
}

* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

body {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    text-align: center;
}

@-webkit-keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes fillText {
    0% {
        fill: rgba(51, 51, 51, 0);
    }
    100% {
        fill: rgba(51, 51, 51, 1);
    }
}

@keyframes fillText {
    0% {
        fill: rgba(51, 51, 51, 0);
    }
    100% {
        fill: rgba(51, 51, 51, 1);
    }
}

@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
