.bulletcomponent {
    .container {
        position: relative;
        background-size: cover;
        background-position: center;
        color: white;
        display: flex;
        align-items: center;

        .title {
            h2 {
                margin-bottom: 20px;
                font-family: Montserrat, Cantarell;
                font-size: 75px;
            }
        }

        .desc {
            li {
                margin: 0 70px 30px 70px;
                font-size: 30px;
                text-align: left;

                strong {
                    font-family: Montserrat, Cantarell;
                    font-size: 30px;
                }
            }
        }
    }
}
