@media all and (max-width: 599px) {
    .infocomponent {
        .container {
            .title {
                h2 {
                    font-size: 45px;
                    text-align: center;
                }
            }

            .desc {
                p {
                    margin: 0 20px 10px 20px;
                    font-size: 18px;
                }

                a {
                    font-size: 18px;
                    padding: 10px 30px;
                }

                a:hover {
                    padding: 10px 50px;
                }
            }
        }
    }
}
