.titlescreen {
    min-height: 100vh; /* Ensures the component takes at least the full height of the viewport */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevents scrolling to see elements below */
    text-align: center; /* Center the text horizontally */

    .container {
        .anim-text {
            height: 340px;
            display: flex;
            justify-content: center;
            align-items: center;

            .drawn-text {
                .text {
                    font-family: ruthligos;
                    font-size: 350px;
                    fill: none;
                    stroke: #333;
                    stroke-width: 2;
                    stroke-dasharray: 1000;
                    stroke-dashoffset: 1000;
                    -webkit-animation:
                        draw 3s forwards,
                        fillText 5s forwards;
                    animation:
                        draw 3s forwards,
                        fillText 5s forwards;
                }
            }
        }

        .title-sub {
            font-size: 40px;
            animation: fade 4s forwards;
        }
    }
}
