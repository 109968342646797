.appcomponent {
    display: flex;
    padding: 20px;
    margin: 50px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .app-images {
        width: 25%;
        margin-right: 20px;

        .each-slide {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 300px;
                height: 300px;
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }

    .app-content {
        display: block;

        h2 {
            margin: 0;
            padding-bottom: 10px;
            font-size: 75px;
        }

        p {
            margin: 0;
            text-align: left;
            padding-bottom: 20px;
            font-size: 30px;
        }

        a {
            transition: 0.2s ease-in-out;
            display: inline-block;
            margin-top: 25px;
            color: whitesmoke;
            font-weight: bolder;
            font-size: 27px;
            background-color: darkslateblue;
            padding: 15px 70px;
            border-radius: 15px;
        }

        a:hover {
            transition: 0.2s ease-in-out;
            padding: 15px 90px;
        }
    }
}
