@font-face {
  font-family: goodtiming;
  src: url("../font/goodtiming.otf");
}
@font-face {
  font-family: ruthligos;
  src: url("../font/Ruthligos.ttf");
}
@font-face {
  font-family: Montserrat;
  src: url("../font/Montserrat.ttf");
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  text-align: center;
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes fillText {
  0% {
    fill: rgba(51, 51, 51, 0);
  }
  100% {
    fill: rgb(51, 51, 51);
  }
}
@keyframes fillText {
  0% {
    fill: rgba(51, 51, 51, 0);
  }
  100% {
    fill: rgb(51, 51, 51);
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-container .desc {
  margin: 50px;
  font-size: 22px;
}

header {
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: center;
  position: relative;
  z-index: 1000;
}
header .container {
  display: flex;
  height: inherit;
  margin: 0 50px;
  width: 100%;
}
header .container .logo {
  display: block;
  text-align: center;
}
header .container .logo .drawn-text .text {
  font-family: ruthligos;
  font-size: 50px;
  fill: rgb(51, 51, 51);
  stroke: #333;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
header .container .logo .drawn-text .text:hover {
  animation: draw 2s forwards;
}
header .container .links {
  display: flex;
  margin: auto;
  margin-right: 0;
}
header .container .links li {
  display: flex;
  margin: 0 40px;
  list-style: none;
  width: 150px;
  justify-content: center;
}
header .container .links li a {
  font-family: Montserrat, Cantarell;
  font-size: 27px;
  text-decoration: none;
  transition: all 0.3s ease;
  color: black;
  font-weight: normal;
  position: relative;
}
header .container .links li a:visited {
  color: black;
}
header .container .links li a:hover {
  font-weight: bold;
}
header .container .links li a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
header .container .links li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: black;
  bottom: -5px;
  left: 0;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}
header .container .hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}
header .container .hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
  transition: 0.4s;
}

footer {
  display: flex;
  width: 100%;
  height: 150px;
  justify-content: center;
}
footer .container {
  display: flex;
  flex-direction: column;
  height: inherit;
  margin: 0 50px;
  width: 100%;
}
footer .container .logo {
  display: block;
  text-align: center;
}
footer .container .logo .drawn-text .text {
  font-family: ruthligos;
  font-size: 50px;
  fill: rgb(51, 51, 51);
  stroke: #333;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
}
footer .container .logo .drawn-text .text:hover {
  animation: draw 2s forwards;
}
footer .container .footnote {
  margin-bottom: 40px;
}

.titlescreen {
  min-height: 100vh; /* Ensures the component takes at least the full height of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents scrolling to see elements below */
  text-align: center; /* Center the text horizontally */
}
.titlescreen .container .anim-text {
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titlescreen .container .anim-text .drawn-text .text {
  font-family: ruthligos;
  font-size: 350px;
  fill: none;
  stroke: #333;
  stroke-width: 2;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: draw 3s forwards, fillText 5s forwards;
  animation: draw 3s forwards, fillText 5s forwards;
}
.titlescreen .container .title-sub {
  font-size: 40px;
  animation: fade 4s forwards;
}

.infocomponent .container {
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  align-items: center;
}
.infocomponent .container .title h2 {
  margin-bottom: 20px;
  font-family: Montserrat, Cantarell;
  font-size: 75px;
}
.infocomponent .container .desc {
  text-align: center;
}
.infocomponent .container .desc p {
  margin: 0 70px 10px 70px;
  font-size: 30px;
}
.infocomponent .container .desc a {
  transition: 0.2s ease-in-out;
  display: inline-block;
  margin-top: 25px;
  color: whitesmoke;
  font-weight: bolder;
  font-size: 27px;
  background-color: darkslateblue;
  padding: 15px 70px;
  border-radius: 15px;
}
.infocomponent .container .desc a:hover {
  transition: 0.2s ease-in-out;
  padding: 15px 90px;
}

.titlecomponent .container {
  position: relative;
  background-size: cover;
  background-position: center;
  padding: 250px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titlecomponent .container .title h2 {
  margin: 0;
  font-family: Montserrat, Cantarell;
  font-size: 100px;
}

.bulletcomponent .container {
  position: relative;
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  align-items: center;
}
.bulletcomponent .container .title h2 {
  margin-bottom: 20px;
  font-family: Montserrat, Cantarell;
  font-size: 75px;
}
.bulletcomponent .container .desc li {
  margin: 0 70px 30px 70px;
  font-size: 30px;
  text-align: left;
}
.bulletcomponent .container .desc li strong {
  font-family: Montserrat, Cantarell;
  font-size: 30px;
}

.appcomponent {
  display: flex;
  padding: 20px;
  margin: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.appcomponent .app-images {
  width: 25%;
  margin-right: 20px;
}
.appcomponent .app-images .each-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.appcomponent .app-images .each-slide img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 4px;
}
.appcomponent .app-content {
  display: block;
}
.appcomponent .app-content h2 {
  margin: 0;
  padding-bottom: 10px;
  font-size: 75px;
}
.appcomponent .app-content p {
  margin: 0;
  text-align: left;
  padding-bottom: 20px;
  font-size: 30px;
}
.appcomponent .app-content a {
  transition: 0.2s ease-in-out;
  display: inline-block;
  margin-top: 25px;
  color: whitesmoke;
  font-weight: bolder;
  font-size: 27px;
  background-color: darkslateblue;
  padding: 15px 70px;
  border-radius: 15px;
}
.appcomponent .app-content a:hover {
  transition: 0.2s ease-in-out;
  padding: 15px 90px;
}

.contactcomponent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactcomponent form {
  width: 350px;
  max-width: 450px;
}
.contactcomponent form .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}
.contactcomponent form .form-group label {
  margin-bottom: 5px;
}
.contactcomponent form .form-group input,
.contactcomponent form .form-group textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.contactcomponent form .form-group textarea {
  resize: none;
  height: 120px;
}
.contactcomponent form button {
  transition: 0.2s ease-in-out;
  display: inline-block;
  margin-top: 25px;
  color: whitesmoke;
  font-weight: bolder;
  font-size: 20px;
  background-color: darkslateblue;
  padding: 15px 50px;
  border-radius: 15px;
  border: none;
}
.contactcomponent form button:hover {
  transition: 0.2s ease-in-out;
  padding: 15px 70px;
  cursor: pointer;
}

/*IPAD*/
@media all and (min-width: 600px) and (max-width: 1080px) {
  .titlescreen {
    padding: unset;
    height: 100vh;
  }
  .titlescreen .container .anim-text .drawn-text {
    width: unset;
  }
  .titlescreen .container .anim-text .drawn-text .text {
    font-family: ruthligos;
    font-size: 350px;
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: draw 3s forwards, fillText 5s forwards;
  }
  .titlescreen .container .title-sub {
    font-size: 25px;
    animation: fade 4s forwards;
  }
}
@media all and (min-width: 600px) and (max-width: 1080px) {
  header {
    height: 130px;
  }
  header .container .logo .drawn-text .text {
    font-size: 50px;
  }
  header .container .links {
    position: absolute;
    top: 0;
    flex-direction: column;
    background-color: white;
    width: unset;
    text-align: center;
    display: none;
  }
  header .container .links li {
    margin: 8px 0;
    width: 290px;
  }
  header .container .links li a {
    font-size: 22px;
  }
  header .container .links.open {
    display: flex;
    justify-content: center;
  }
  header .container .hamburger {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: 35%;
  }
}
@media all and (min-width: 600px) and (max-width: 1080px) {
  .infocomponent .container .title h2 {
    font-size: 55px;
    text-align: center;
  }
  .infocomponent .container .desc p {
    margin: 0 20px 10px 20px;
    font-size: 25px;
  }
  .infocomponent .container .desc a {
    font-size: 25px;
    padding: 10px 30px;
  }
  .infocomponent .container .desc a:hover {
    padding: 10px 50px;
  }
}
@media all and (min-width: 600px) and (max-width: 1080px) {
  .bulletcomponent .container .title h2 {
    font-size: 45px;
  }
  .bulletcomponent .container .desc li {
    margin: 0 20px 30px 20px;
    font-size: 22px;
  }
  .bulletcomponent .container .desc li strong {
    font-size: 25px;
  }
}
@media all and (min-width: 600px) and (max-width: 1080px) {
  .appcomponent {
    flex-direction: column;
    margin: 20px;
  }
  .appcomponent .app-images {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .appcomponent .app-images .each-slide img {
    width: 330px;
    height: 300px;
  }
  .appcomponent .app-content {
    text-align: center;
  }
  .appcomponent .app-content h2 {
    font-size: 45px;
    padding-bottom: 5px;
  }
  .appcomponent .app-content p {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .appcomponent .app-content a {
    font-size: 22px;
    padding: 10px 30px;
  }
}
/*IPHONE*/
@media all and (max-width: 599px) {
  .titlescreen {
    padding: unset;
    height: 20vh;
    min-height: 90vh;
  }
  .titlescreen .container .anim-text {
    height: unset;
  }
  .titlescreen .container .anim-text .drawn-text {
    width: unset;
  }
  .titlescreen .container .anim-text .drawn-text .text {
    font-family: ruthligos;
    font-size: 320px;
    fill: none;
    stroke: #333;
    stroke-width: 2;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    animation: draw 3s forwards, fillText 5s forwards;
  }
  .titlescreen .container .title-sub {
    font-size: 20px;
    animation: fade 4s forwards;
  }
}
@media all and (max-width: 599px) {
  header {
    height: 130px;
  }
  header .container .logo .drawn-text .text {
    font-size: 50px;
  }
  header .container .links {
    position: absolute;
    top: 0;
    flex-direction: column;
    background-color: white;
    width: unset;
    text-align: center;
    display: none;
  }
  header .container .links li {
    margin: 8px 0;
    width: 290px;
  }
  header .container .links li a {
    font-size: 22px;
  }
  header .container .links.open {
    display: flex;
    justify-content: center;
  }
  header .container .hamburger {
    display: flex;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: 35%;
  }
}
@media all and (max-width: 599px) {
  .infocomponent .container .title h2 {
    font-size: 45px;
    text-align: center;
  }
  .infocomponent .container .desc p {
    margin: 0 20px 10px 20px;
    font-size: 18px;
  }
  .infocomponent .container .desc a {
    font-size: 18px;
    padding: 10px 30px;
  }
  .infocomponent .container .desc a:hover {
    padding: 10px 50px;
  }
}
@media all and (max-width: 599px) {
  .titlecomponent .container {
    padding: 100px;
  }
  .titlecomponent .container .title h2 {
    font-size: 63px;
  }
}
@media all and (max-width: 599px) {
  .bulletcomponent .container .title h2 {
    font-size: 45px;
  }
  .bulletcomponent .container .desc li {
    margin: 0 20px 30px 20px;
    font-size: 22px;
  }
  .bulletcomponent .container .desc li strong {
    font-size: 25px;
  }
}
@media all and (max-width: 599px) {
  .appcomponent {
    flex-direction: column;
    margin: 20px;
  }
  .appcomponent .app-images {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .appcomponent .app-images .each-slide img {
    width: 330px;
    height: 300px;
  }
  .appcomponent .app-content {
    text-align: center;
  }
  .appcomponent .app-content h2 {
    font-size: 45px;
    padding-bottom: 5px;
  }
  .appcomponent .app-content p {
    font-size: 22px;
    padding-bottom: 10px;
  }
  .appcomponent .app-content a {
    font-size: 22px;
    padding: 10px 30px;
  }
}
@media all and (max-width: 599px) {
  form {
    width: 100%;
    padding: 0 20px;
  }
  form .form-group label,
  form .form-group input,
  form .form-group textarea {
    font-size: 20px;
  }
  form .form-group textarea {
    height: 100px;
  }
  form button {
    padding: 10px;
    font-size: 16px;
  }
}

