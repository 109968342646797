@media all and (min-width: 600px) and (max-width: 1080px) {
    .appcomponent {
        flex-direction: column;
        margin: 20px;

        .app-images {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;

            .each-slide {
                img {
                    width: 330px;
                    height: 300px;
                }
            }
        }

        .app-content {
            text-align: center;

            h2 {
                font-size: 45px;
                padding-bottom: 5px;
            }

            p {
                font-size: 22px;
                padding-bottom: 10px;
            }

            a {
                font-size: 22px;
                padding: 10px 30px;
            }
        }
    }
}
