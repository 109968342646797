@media all and (max-width: 599px) {
    .bulletcomponent {
        .container {
            .title {
                h2 {
                    font-size: 45px;
                }
            }

            .desc {
                li {
                    margin: 0 20px 30px 20px;
                    font-size: 22px;

                    strong {
                        font-size: 25px;
                    }
                }
            }
        }
    }
}
